import React from 'react';
import _ from 'lodash';

import Social from './Social';
import Contact from './Contact';

export default class Footer extends React.Component {
    render() {
        return (
            <section id="footer" className="wrapper split style2">
                <div className="inner">
                    {_.get(this.props, 'pageContext.site.siteMetadata.social.enabled') && 
                        <Social {...this.props} />
                    }
                    {_.get(this.props, 'pageContext.site.siteMetadata.footer.enable_contact_section') && 
                        <Contact {...this.props} />
                    }
                </div>
                <div className="copyright">
                    <p>Brought to you in part by:</p>
                    <p><a href="https://www.stackbit.com/" target="_blank">Stackbit</a>-><a href="https://www.netlify.com/" target="_blank">Netlify</a>-><a href="https://www.gatsbyjs.org/" target="_blank">Gatsby</a>-><a href="https://reactjs.org/" target="_blank">React</a></p>
                    <p>{_.get(this.props, 'pageContext.site.siteMetadata.footer.line_phrase')}</p>
                </div>
            </section>
        );
    }
}
